body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  min-height: 100%;
}

.ui.dimmer {
  position: fixed !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.contactInfoData {
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}

/*The modal seems a bit buggy in fullscreen mode without inline custom css*/
.visible.transition {
  display: inline-block !important;
  position: relative;
}

/* Sendbird overrides */

.sendbird-modal {
  z-index: 6 !important;
}

.sendbird-icon > svg > path {
  fill: #3089f3 !important;
}

.sendbird-theme--light .sendbird-channel-preview--active {
  border-left: solid 4px #FBB032 !important;
  background: #FFFFFF !important;
}

.sendbird-channel-preview__content__upper__header__channel-name {
  color: #3089f3 !important;
}

.sendbird-message-input .sendbird-message-input--attach {
  bottom: 14px !important;
}

.sendbird-channel-header__title:active {
  border: 2px solid #3089f3 !important;
}

#sendbird-message-input-text-field:active {
  border: 2px solid #3089f3 !important;
}

#sendbird-message-input-text-field:focus {
  border: 2px solid #3089f3 !important;
}

.sendbird-button--primary {
  background: #3089f3 !important;
  border: 1px solid #3089f3 !important;
}

.sendbird-button--secondary {
  border: 1px solid #3089f3 !important;
}

.sendbird-label--color-primary {
  color: #3089f3 !important;
}

.sendbird-checkbox--checkmark:active {
  border: 2px solid #3089f3 !important;
}

.sendbird-theme--light .sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark {
  background: #3089f3 !important;
  border: 2px solid #3089f3 !important;
  box-shadow: 0px 0px 0px 2px #3089f3 !important;
}

.sendbird-theme--light .sendbird-checkbox--checkmark {
  border: 2px solid #3089f3 !important;
}

.sendbird-message-content__middle__message-item-body {
  background: #3089f3 !important;
}

.sendbird-message-input--send {
  bottom: 13px !important;
}

span .incoming {
  background: #FBB032 !important;
}

.sendbird-conversation__messages {
  overflow-y: hidden !important;
}

.sendbird-conversation__messages-padding {
  overflow-x: hidden !important;
}

.sendbird-chat-header {
  height: 50px !important;
  min-height: 0px !important;
}

.sendbird-chat-header__right {
  display: none !important;
}

.sendbird-chat-header--avatar--group-channel {
  display: none !important;
}